import React from 'react'
import "./Leaderboard.css"
import icon from "../assets/hexa.jpg"
import rank from "../assets/rank.png"
import coin1 from "../assets/star.png"
import coin2 from "../assets/gelt.png"
import facebook from "../assets/facebook.png"
import telegram from "../assets/telegram.png"
import twitter from "../assets/twitter.png"
import whatsapp from "../assets/whatsapp.png"

const Leaderboards = () => {
  return (
    <>

      <div id="box">
        <div id="leaderboard">
          <ol>
            <div id="A">
              <li ><span className="bi bi-trophy"></span> LEADERBOARDS</li>
              <li>(YOUR RANKING 2'nd)</li>
              <li>EARNING</li>
            </div>
            <div id="B">
              <li>1</li>
              <img src={icon} alt='icon'/>
              <li>
                <div>JEAN-LOUP AUTRET</div> <p>lssy Less Moulineaux</p> 
              </li>
             
              <li><span><img src={rank} alt="" /> </span>  2,1050.05 MLD</li>

            </div>
            <div id="B">
              <li>2</li>
              <img src={icon} alt='icon' /><li>
                <div>JAY PAUL</div> <p>lssy Less Moulineaux</p> </li>
             
                <li><span> <img src={coin1} alt="icon"  /> </span> 2,1050.05 MLD</li>

            </div>
            <div id="B">
              <li>3</li>
              <img src={icon} alt='icon'/><li>
                <div>ELIZA SAUN</div> <p>lssy Less Moulineaux</p> </li>
             <li><span> <img src={coin2} alt="icon" /></span> 2,1050.05 MLD</li>

            </div>
            <div id="B">
              <li>4</li>
              <img src={icon} alt='icon'/><li>
                <div>LOUP AUTRET</div> <p>lssy Less Moulineaux</p> </li>
              <li>865 MLD</li>

            </div>
            <div id="B">
              <li>5</li>
              <img src={icon} alt='icon' /><li className='me-4'>
                <div>SHAMKAR LOY</div> <p>lssy Less Moulineaux</p> </li>
              <li>860 MLD</li>

            </div>
          </ol>
          <div id="foot1"  className='set'>
        <div>   
         <p className='text-white h6 ms-2 mt-3'>Refer and Earn more</p>
         <p className='text-white ms-2'>Get SMLD <span className='text-info'>Promo code for new accounts!</span></p> 
        </div>
        <div>
          <div className='input-group'>
          <input type="text" className='mt-2' placeholder='0x00AsnkJUI02450hgy2' />
            <div className='mt-2'>
            <button className="bi bi-folder2-open bg-white input-group-append " id="btnn"></button>
            </div>
           
          </div>
        </div>
    </div>
          <div id="icons">
            <span className='text-white' id="source">Source Code :</span>
             <img src={facebook} alt="" />
            <img src={telegram} alt="" />
            <img src={twitter} alt="" />
            <img src={whatsapp} alt="" />

          </div>
        </div>


      </div>
    </>
  )
}

export default Leaderboards
