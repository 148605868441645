import Header from "./components/Header";
import { BrowserRouter, Routes,Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Leaderboards from "./components/Leaderboards";




function App() {
  return (
   <BrowserRouter>
   <Header/>
      <Routes>
        <Route path="leader" element={<Leaderboards/>} />
     
        
      </Routes>
      <Navbar/>
   </BrowserRouter>
   
  );
}

export default App;
